import styled from "styled-components"
import media from "styled-media-query"

import Img from "gatsby-image"
import { Link } from "gatsby"

import { Home } from "@styled-icons/material/Home"
import { Email } from "@styled-icons/material-outlined/Email"
import { Image } from "@styled-icons/boxicons-regular/Image"
import { PencilSquare } from "@styled-icons/bootstrap/PencilSquare"
import { GraduationCap } from "@styled-icons/entypo/GraduationCap"

export const HeaderToggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 10vw;

  ${media.lessThan("large")`
    display: flex;
  `}
`

export const HeaderNavBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  ${media.lessThan("large")`
   flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: center;
    padding: 1.5rem;
    background-color: var(--white);
    transition: all 0.3s ease-in;
    top: 0;
    z-index: 1500;
    left: ${props => (props.open ? "-100%" : "0")};
    `}
`

export const HeaderHamburguer = styled.div`
  background-color: var(--alert_button);
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};
  display: none;

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: var(--alert_button);
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }

  ${media.lessThan("large")`
    display: block;
  `}
`

export const HeaderWrapper = styled.header`
  padding: 50px 5rem 0 5rem;
  background-color: var(--white);
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 13px 15px 0px rgba(0, 0, 0, 0.5);
  position: relative;

  ${media.lessThan("large")`
    padding: 86px .5rem 0;
    position: fixed;
    top: 0;
    z-index: 1000;
  `}
`

export const HeaderLogo = styled(Img)`
  width: 300px;

  ${media.lessThan("large")`
    width:200px;
  `}
`

export const HeaderMenu = styled.nav`
  padding: 1.25rem 0 1.25rem 1.25rem;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;

  ${media.lessThan("large")`
    flex-direction: column;
    align-items: center;
  `}
`

export const HeaderMenuItem = styled(Link)`
  padding: 1.25rem 1rem;
  color: ${props => (props.color ? props.color : "#000")};
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  cursor: pointer;
  align-items: center;

  &:hover {
    text-decoration: underline;
    background-color: rgba(0, 0, 0, 0.05);
  }

  ${media.lessThan("large")`
    padding: 1rem;
    text-align: center;
  `}
`

export const HeaderMenuItemLogo = styled(Link)`
  text-decoration: none;
  cursor: pointer;

  ${media.lessThan("large")`
    width:200px;
  `}
`

export const HomeIcon = styled(Home)`
  width: 24px;
  height: 24px;
  margin-right: 0.25rem;
`

export const ImageIcon = styled(Image)`
  width: 24px;
  height: 24px;
  margin-right: 0.25rem;
`

export const EmailIcon = styled(Email)`
  width: 24px;
  height: 24px;
  margin-right: 0.25rem;
`
export const PencilSquareIcon = styled(PencilSquare)`
  width: 20px;
  height: 20px;
  margin-right: 0.25rem;
`
export const GraduationCapIcon = styled(GraduationCap)`
  width: 24px;
  height: 24px;
  margin-right: 0.25rem;
`
