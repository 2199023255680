import React from "react"
import PropTypes from "prop-types"

import * as S from "./styled"

const TopAlert = ({ text, link, txtBtn }) => {
  return (
    <S.TopAlertWrapper>
      <S.TopAlertText>{text}</S.TopAlertText>
      <S.TopAlertButton target="_blank" href={link}>
        {txtBtn}
      </S.TopAlertButton>
    </S.TopAlertWrapper>
  )
}

TopAlert.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  txtBtn: PropTypes.string.isRequired,
}

export default TopAlert
