import React from "react"

import * as S from "./styled"

const NavbarLinks = () => {
  return (
    <S.HeaderMenu>
      <S.HeaderMenuItem color="var(--green_logo)" to="/">
        <S.HomeIcon /> Home
      </S.HeaderMenuItem>
      <S.HeaderMenuItem color="var(--blue_logo)" to="/gallery">
        <S.ImageIcon /> Galeria
      </S.HeaderMenuItem>
      <S.HeaderMenuItem color="var(--yellow_logo)" to="/mais-cursos">
        <S.GraduationCapIcon /> Mais Cursos
      </S.HeaderMenuItem>
      <S.HeaderMenuItem color="var(--orange_logo_hover)" to="/contact">
        <S.EmailIcon /> Contato
      </S.HeaderMenuItem>
    </S.HeaderMenu>
  )
}

export default NavbarLinks
