import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styled"

const Footer = () => {
  const { logoImage } = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "favicon.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <S.FooterWrapper>
      <S.FooterBox>
        <S.FooterLogo
          fixed={logoImage.childImageSharp.fixed}
          alt="Logotipo - Uma mão segurando um lápis com um colorido em aquarela por trás."
        />
        <S.FooterText>
          Aprendendo Desenhar · 2020 © Todos os Direitos Reservados.{" "}
        </S.FooterText>
        <S.FooterText>
          Feito com S2 por{" "}
          <S.FooterLink href="https://darknblack.com" target="_blank">
            Dark n' Black iStudio
          </S.FooterLink>
        </S.FooterText>
      </S.FooterBox>
      <S.FooterBox>
        <S.FooterMenu>
          <S.FooterMenuItem to="/polices">
            Política de privacidade
          </S.FooterMenuItem>
          <S.FooterMenuItem to="/terms">Termos de Uso</S.FooterMenuItem>
          <S.FooterMenuItem to="/disclaimer">Disclaimer</S.FooterMenuItem>
          <S.FooterMenuItem to="/legal">Aviso Legal</S.FooterMenuItem>
        </S.FooterMenu>
      </S.FooterBox>
    </S.FooterWrapper>
  )
}

export default Footer
