import styled from "styled-components"
import media from "styled-media-query"

import { Link } from "gatsby"
import Img from "gatsby-image"

export const GeneralWrapper = styled.article`
  padding: 2rem 1.5rem;
  color: var(--text_color);

  ${media.lessThan("large")`
    padding:.5rem .75rem;
  `}
`

export const GeneralSection = styled.section`
  display: flex;
  width: 100%;

  ${media.lessThan("large")`
    flex-direction: column;
  `}
`

export const GeneralBox = styled.div`
  padding: 1rem;
  width: 100%;
  margin: 0.5rem;
  box-shadow: ${props =>
    props.shadow ? "none" : "3px 3px 15px 3px rgba(0, 0, 0, 0.5)"};

  ${media.lessThan("large")`
      padding:.25rem .25rem .25rem .75rem;
      margin: 0 0 1rem 0;
    `}
`

export const GeneralImageCursos = styled(Img)`
  width: 40%;
  margin-left: 1rem;
  height: auto;

  ${media.lessThan("large")`
    margin: 0 0 1rem 0;
  `}
`

export const GeneralImagePequena = styled(Img)`
  width: 360px;
  margin: 0.5rem;
  cursor: pointer;
  box-shadow: ${props =>
    props.shadow ? "3px 3px 15px 3px rgba(0, 0, 0, 0.5)" : "none"};
  height: auto;

  ${media.lessThan("large")`
    width: 100%;
    margin: 0 0 1rem 0;
  `}
`

export const GeneralGaleria = styled.div`
  display: grid;
  grid-area: posts;
  grid-gap: 1px;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));

  ${media.lessThan("large")`
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  `}
`

export const GeneralTitle = styled.h1`
  font-size: 3rem;
  text-align: ${props => (props.align ? props.align : "center")};
  font-family: ${props =>
    props.font ? "Poppins, sans-serif" : "Indie Flower, cursive"};
  margin: 0 auto 4rem;
`

export const GeneralSubTitle = styled.h2`
  margin: 2.5rem auto;
  text-align: ${props => (props.align ? props.align : "center")};
  font-size: 2.25rem;
  text-transform: uppercase;
`

export const Generalh3 = styled.h3`
  margin: 1rem auto;
  text-align: center;
  font-size: 1.75rem;
`

export const GeneralHighlight = styled.p`
  margin: 1.5rem auto;
  line-height: 1.5;
  font-style: italic;
  font-size: 2rem;
  text-align: center;
  padding: 2.5rem;

  ${media.lessThan("large")`
    padding: .5rem;
  `}
`

export const GeneralText = styled.p`
  line-height: 1.5;
  font-size: 1.25rem;
  margin: 1.5rem auto;
`

export const GeneralTextTitle = styled.p`
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.25rem;
`

export const GeneralTextCapitular = styled.p`
  margin: 1.5rem auto;
  font-size: 1.25rem;
  line-height: 1.5;

  &::first-letter {
    font-size: 3rem;
    line-height: 0.68;
    margin: 0.5rem 1rem 0.5rem 0;
    float: left;
  }
`

export const GeneralStrongText = styled.strong`
  font-weight: 700;
  font-size: 1.25rem;
`

export const GeneralIcon = styled.span`
  display: block;
  width: 5rem;
  margin: 1rem auto;
  color: ${props => (props.color ? props.color : "#000")};
`

export const GeneralExternalLink = styled.a`
  text-decoration: none;
`

export const GeneralCallToAction = styled.a`
  display: block;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.5rem;
  transition: transform 0.2s;
  color: var(--alert_button);

  &:hover {
    text-decoration: underline;
    transform: scale(1.25);
  }
`

export const GeneralFinalCall = styled.a`
  display: block;
  margin: 2rem auto 0;
  background: var(--alert_button);
  border-radius: 0.5rem;
  text-decoration: none;
  color: var(--white);
  text-align: center;
  padding: 1rem 1rem;
  cursor: pointer;
  font-size: 1.5rem;

  &:hover {
    background-color: var(--alert_hover);
    text-decoration: underline;
  }
`

export const GeneralSeparator = styled.hr`
  margin: 2.5rem auto;
  display: block;
  width: ${props => (props.witdh ? "75%" : "50%")};
`

export const GeneralBlockQuote = styled.blockquote`
  color: var(--alert_button);
  background-color: #fcfcfc;
  border-left: 10px solid var(--alert_hover);
  margin: 1.6rem 0;
  padding: 1.6rem 2.4rem 1.6rem 3.2rem;
  position: relative;
  clear: both;
  font-size: 1.75rem;
  line-height: 33px;
`
export const GeneralList = styled.ul`
  list-style: none;
  margin: 1.5rem;
`

export const GeneralListItem = styled.li`
  line-height: 1.5;
  font-size: 1.25rem;
`
export const GeneralLink = styled(Link)`
  text-decoration: none;
  color: var(--alert_button);

  &:hover {
    text-decoration: underline;
  }
`
export const GeneralItem = styled(Link)`
  width: 100%;
  padding-left: 1rem;
  text-decoration: none;
  color: var(--text_color);
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;

  &:hover {
    box-shadow: ${props =>
      props.box ? props.box : "0 0 57px 11px var(--green_logo)"};
  }

  & div.texto {
    width: 90%;
    display: block;
  }

  ${media.lessThan("large")`
    flex-direction: column;
    box-shadow: 0 0 27px 3px var(--text_color);
  `}
`
export const GeneralItemImage = styled(Img)`
  width: 500px;
  object-fit: contain;
  height: 300px;
  align-self: flex-end;
  justify-self: flex-end;

  ${media.lessThan("large")`
    width: 100%;
  `}
`

export const GeneralImageSafe = styled(Img)`
  margin: 0 auto;
  width: 500px;

  ${media.lessThan("large")`
    width: 100%;
  `}
`
export const GeneralTextDiscount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
  padding-bottom: 0.15rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
`

export const GeneralTextThroughLine = styled.p`
  display: inline;
  color: var(--pink_logo_hover);
  text-decoration: line-through;
  font-weight: 700;
  font-size: ${props => (props.size ? props.size : "1rem")};
  text-align: right;
`

export const GeneralFinalBoxPrice = styled.div`
  width: 100%;
  text-align: center;
`
