import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
    /* http://meyerweb.com/eric/tools/css/reset/
    v2.0 | 20110126
    License: none (public domain)
    */

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 62.5%;
        font: inherit;
        vertical-align: baseline;
        box-sizing: border-box;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    body {
        background: var(--white);
        line-height: 1;
        font-size: 100%;
        font-family: 'Poppins', sans-serif;
    }
    img {
        display: block;
        width: 100%;
        height: auto;
    }

    /* Variables */
    body {
        --white: #ffffff;
        --alert_button: #04bf8a;
        --alert_back: #18298c;
        --alert_hover: #03a678;
        --black_pure: #000000;
        --text_color: #333333;
        --background_transparente: rgba(231,231,231,.4);
        --white_transparent: rgba(255,255,255,.4);
        --facebook: #4267b2;
        --facebook_hover: #2050b3;
        --green_logo: #70ca57;
        --green_logo_hover: #065918;
        --blue_logo:#1fa1df;
        --blue_logo_hover:#0d6db1;
        --orange_logo:#faab4e;
        --orange_logo_hover:#fe5421;
        --pink_logo: #de5aa5;
        --pink_logo_hover: #b52a74;
        --yellow_logo: #febc28;
    }
`

export default GlobalStyles
