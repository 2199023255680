import styled from "styled-components"
import media from "styled-media-query"

export const TopAlertWrapper = styled.div`
  width: 100vw;
  height: 55px;
  background-color: var(--alert_back);
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  ${media.lessThan("large")`
    flex-direction: column;
    height: auto;
    padding: .5rem;
    z-index: 1200;
  `}
`

export const TopAlertText = styled.p`
  color: var(--white);

  ${media.lessThan("large")`
    margin-bottom:.5rem;
    text-align: center;
    line-height: 1.3;
    font-size:.85rem;
  `}
`

export const TopAlertButton = styled.a`
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  color: var(--white);
  background-color: var(--alert_button);
  margin-left: 1.5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  text-decoration: none;
  transition: background 0.3s;
  cursor: pointer;

  &:hover {
    background: var(--alert_hover);
  }
`

export const TopAlertCloseButton = styled.span`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 5rem;
  justify-self: flex-end;
  color: var(--white);
`
